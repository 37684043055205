* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body {
  background-image: url("../images/bgsaad.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.glass-des {
  background: rgba(255, 255, 255, 0.123);
  // box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  // backdrop-filter: blur(4px);
  // -webkit-backdrop-filter: blur(4px);
  // border-radius: 10px;
  // border: 1px solid rgba(255, 255, 255, 0.18);
}
.glassed {
  background: rgba(255, 255, 255, 0.123);
  // box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  // border-radius: 10px;
  // border: 1px solid rgba(255, 255, 255, 0.18);
}
// .scrollToBottom{
//   scroll-behavior: smooth;
//   window
// }
.custom-scroll {
  height: calc(100vh - 190px);
}
.chat-custom-scroll {
  height: calc(100vh - 160px);
}
.custom-design-chat-container {
  position: relative;
  z-index: 100;
}
.custom-design-chat-container::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 40px;
  background-color: #7269ee;
  left: 0px;
  bottom: -20px;
  // z-index: 0;
}
.custom-triangle {
  position: absolute;
  width: 30px;
  height: 20px;
  border-top-left-radius: 40px;
  background-color: #252e35;
  left: 0px;
  bottom: -20px;
  // transform: rotate(10deg);
  z-index: 1;
}
.custom-design-chat-right-container {
  position: relative;
  z-index: 100;
}
.custom-design-chat-right-container::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 40px;
  background-color: #36404a;
  right: 0px;
  bottom: -20px;
  // z-index: 0;
}
.custom-triangle-right {
  position: absolute;
  width: 30px;
  height: 20px;
  border-top-right-radius: 40px;
  background-color: #252e35;
  right: 0px;
  bottom: -20px;
  // transform: rotate(10deg);
  z-index: 1;
}

// ::-webkit-scrollbar {
//   width: 50px !important ;
// }

/* Track */
// ::-webkit-scrollbar-track {
//   background: #f1f1f1 !important;
// }

/* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #888;
// }

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }

// .chat-bubble {
// background-color:#E6F8F1;
// padding:16px 28px;
// -webkit-border-radius: 20px;
// -webkit-border-bottom-left-radius: 2px;
// -moz-border-radius: 20px;
// -moz-border-radius-bottomleft: 2px;
// border-radius: 20px;
// border-bottom-left-radius: 2px;
// display:inline-block;
// }
.typing {
  align-items: center;
  display: flex;
  height: 17px;
  margin-top: 6px;
  padding-left: 5px;
}
.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #5e5dd0; //rgba(20,105,69,.7);
  border-radius: 50%;
  height: 5px;
  width: 5px;
  margin-right: 4px;
  vertical-align: middle;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #7269ee; // rgba(20,105,69,.7);
  }
  28% {
    transform: translateY(-7px);
    background-color: #8e86f6; //rgba(20,105,69,.4);
  }
  44% {
    transform: translateY(0px);
    background-color: #b1acff; //rgba(20,105,69,.2);
  }
}

// !!! INPUT FORM
/*********************************
   CUSTOM INPUT FILES FOR IMAGES
 *********************************/

/*** GENERAL STYLES ***/

#page {
  text-align: center;
  h1 {
    // margin-bottom: 4rem;
    font-family: "Lemonada", cursive;
    text-transform: uppercase;
    font-weight: normal;
    color: #fff;
    font-size: 2rem;
  }
}

//* Delete Variable in Contact Dialog
.overlay-custom-class-name {
  background-color: rgba(255, 255, 255, 0.5) !important;
  z-index: 500000 !important;
}
