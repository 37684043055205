/*! FILE INPUT */

/* 200: "#3e4a57",
          300: "#36404a",
          400: "#262e34",
          500: "#303842",
          700: "#252e35", */

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: dashed 2px rgba(225, 225, 225, 0.3);
  width: 200px;
  height: 50px;
  font-size: 12px;
  font-weight: 400;
  color: black;
  padding: 10px 12px;
  background-color: rgba(225, 225, 225, 0.1);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

/*!FILE INPUT */
